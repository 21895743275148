import React, { useEffect } from "react"
import { useState } from "react"
import { Box } from "../components/Box"
import { Button } from "../components/Buttons"
import { Game } from "../components/Game"
import { Section } from "../components/Section"
import { Video, VideoDescription } from "../components/Video"
import { Fade } from "react-awesome-reveal"
import { Link, Trans } from "gatsby-plugin-react-i18next"

export function Battle() {
    return (
        <Section bg="bg-fl-turkis-dark" color="text-fl-text" className="relative pb-0">
            <header className="text-center mx-auto w-128 max-w-full">
                <div className="px-2 relative z-10">
                    <Fade direction="up" triggerOnce cascade duration={800}>
                        <h2 className="text-3xl font-serif text-fl-white">
                            <Trans i18nKey="battleTitle">
                                <span className="text-fl-turkis-light drop-shadow-[0_0_8px_rgba(53,201,181,0.5)]">
                                    Timed
                                </span>{" "}
                                Turn-Based Combat
                            </Trans>
                        </h2>
                        <p className="text-lg mt-4">
                            <Trans i18nKey="battleDesc">
                                Experience a new twist on traditional turn-based combat: time your
                                attacks right for more damage and additional bonuses! Frameland
                                combines strategic thinking with skillful execution.
                            </Trans>
                        </p>
                    </Fade>
                </div>
                <div className="hidden lg:block absolute top-0 left-0 right-0 bottom-0 mx-auto max-w-6xl w-full">
                    <img
                        src="/assets/about/swarm.png"
                        className="absolute left-10 top-0 opacity-30 animate-swarm-glow"
                        draggable="false"
                        alt=""
                    />
                    <img
                        src="/assets/about/swarm.png"
                        className="absolute -right-10 -bottom-20 opacity-30 -rotate-180 animate-swarm-glow [animation-delay:1.3s]"
                        draggable="false"
                        alt=""
                    />
                    <img
                        src="/assets/about/swarm.png"
                        className="absolute -left-20 -bottom-10 opacity-30 -rotate-180 animate-swarm-glow [animation-delay:2.6s]"
                        draggable="false"
                        alt=""
                    />
                </div>
            </header>

            {/* Gradient overlays */}
            <div className="absolute -bottom-20 inset-0 bg-gradient-to-t from-fl-turkis-light/10 via-transparent to-transparent" />

            <div className="relative mt-6 z-10">
                <ul className="max-w-lg lg:max-w-5xl lg:columns-3 gap-2 fl-container">
                    <li className="mb-6">
                        <Box className="w-full">
                            <Video name="timing" />
                        </Box>
                        <VideoDescription>
                            <Trans i18nKey="battle1">
                                <b className="text-fl-white">Timing</b> is everything! Attacks,
                                blocks, and items can all be timed to be more effective.
                            </Trans>
                        </VideoDescription>
                    </li>
                    <li className="mb-6">
                        <Box className="w-full">
                            <Video name="skills" />
                        </Box>
                        <VideoDescription>
                            <Trans i18nKey="battle2">
                                Learn characters' unique skills &amp; customize your play style with
                                the power&nbsp;of&nbsp;<b className="text-fl-white">remnants</b>!
                            </Trans>
                        </VideoDescription>
                    </li>
                    <li className="mb-6">
                        <Box className="w-full">
                            <Video name="overheat" />
                        </Box>
                        <VideoDescription>
                            <Trans i18nKey="battle3">
                                Exploit your opponent's weakness to{" "}
                                <b className="text-fl-white">overheat</b> them. Then cause massive
                                damage!
                            </Trans>
                        </VideoDescription>
                    </li>
                </ul>
            </div>
            {/* <BattleDemo /> */}
        </Section>
    )
}

function BattleDemo(props) {
    const [isMobile, setMobile] = useState(false)
    const [state, setState] = useState("")
    const [isHelperTextVisible, showHelperText] = useState(true)

    useEffect(() => {
        if (typeof window !== "undefined") {
            setMobile(/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent))
        }
    }, [])

    return (
        <div className="mx-auto text-center max-w-full px-2 mt-6 mb-8 py-8 bg-black/50">
            {state !== "loading" && (
                <>
                    <div
                        className="flex items-center justify-center hover:cursor-pointer"
                        onClick={onClick}
                    >
                        <Button disabled={isMobile || state !== ""}>
                            <p className="relative">
                                <span className="inline-block mr-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="absolute top-0 -ml-4"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                    </svg>
                                </span>
                                <Trans i18nKey="battlePlayTitle">Play Now!</Trans>
                                <span className="block normal-case text-xs text-indigo-100">
                                    <Trans i18nKey="battlePlayDesc">
                                        (use Chrome or Firefox for best experience)
                                    </Trans>
                                </span>
                                {isMobile && <span className="text-xs"> (only on PC)</span>}
                            </p>
                        </Button>
                    </div>
                </>
            )}
            {state === "loading" && (
                <div
                    width={680}
                    height={440}
                    className="mt-4 mx-auto max-w-full overflow-x-hidden relative"
                >
                    <Box className="relative">
                        <Loader />
                        <Game />
                    </Box>
                    {!isHelperTextVisible && (
                        <p>
                            <Trans i18nKey="demo1">
                                The screen might remain black for some time before the game loads.
                            </Trans>
                        </p>
                    )}
                    {isHelperTextVisible && (
                        <Fade
                            cascade
                            duration={1500}
                            delay={600}
                            className="p-2 rounded text-xs absolute left-0 top-0 right-0 mt-2"
                        >
                            <p className="mt-2">
                                <Trans i18nKey="demo2">
                                    Please wait while the demo loads... this might take around 30
                                    seconds.
                                </Trans>
                            </p>
                            <p className="mt-8">
                                <Trans i18nKey="demo3">
                                    Early preview build. Does not represent final quality of the
                                    game.
                                </Trans>
                            </p>
                        </Fade>
                    )}
                </div>
            )}
        </div>
    )

    function onClick() {
        if (!isMobile && state === "") {
            setState("loading")
            setTimeout(() => {
                showHelperText(false)
            }, 8000)
        }
    }
}

function Loader({ className }) {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 6000)
    }, [])

    const style = {
        verticalAlign: "-0.125em",
        border: "0.25em solid",
        borderRightColor: "transparent",
    }

    if (loaded) return null

    return (
        <Box isTransparent={false} className="absolute left-0 right-0 top-0 bottom-0">
            <div className="flex justify-center items-center p-4 absolute left-0 right-0 top-0 bottom-0">
                <div
                    className={`animate-spin inline-block w-8 h-8 border-4 rounded-full`}
                    role="status"
                    style={style}
                >
                    <span className="invisible">
                        <Trans i18nKey="loading">Loading...</Trans>
                    </span>
                </div>
            </div>
        </Box>
    )
}
