import React from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import { FaSteam, FaBandcamp, FaYoutube, FaSpotify, FaApple, FaMusic } from "react-icons/fa"
import { SiTidal } from "react-icons/si"
import { BsMusicNoteBeamed } from "react-icons/bs"
import { Section } from "../components/Section"

const buyLinks = [
    {
        name: "Steam",
        url: "https://store.steampowered.com/app/3582680/Frameland_A_Binary_Tale_Soundtrack/",
        icon: FaSteam,
    },
    {
        name: "Bandcamp",
        url: "https://mariodederichs.bandcamp.com/album/frameland-original-soundtrack",
        icon: FaBandcamp,
    },
]

const streamLinks = [
    {
        name: "YouTube",
        url: "https://music.youtube.com/watch?v=D0311-kxX9k&list=OLAK5uy_nHbFUAjvWuq4ThticR1SJOvfj4A490olo",
        icon: FaYoutube,
    },
    {
        name: "Spotify",
        url: "https://open.spotify.com/intl-de/album/6eHTOmIMpxreipBHRp0JMK?si=Y010diCmR9aL2oKDhu070A",
        icon: FaSpotify,
    },
    {
        name: "Apple Music",
        url: "https://music.apple.com/de/artist/mario-dederichs/1799833696",
        icon: FaApple,
    },
    {
        name: "Tidal",
        url: "#",
        icon: SiTidal,
    },
]

const faqItems = [
    {
        question: "What formats are available?",
        answer: "The soundtrack is available in high-quality MP3 (320kbps) format & uncompressed wav format.",
    },
    {
        question: "Can I buy the soundtrack separately from the game?",
        answer: "Yes, you can buy it separately on Steam and Bandcamp.",
    },
    {
        question: "Who composed the music?",
        answer: "It was composed, arranged & produced by Mario Dederichs.",
    },
    {
        question: "Can I use the music in my videos/streams?",
        answer: "Yes, you can use the soundtrack in your content creation. We just ask that you credit Mario Dederichs and link back to the game or soundtrack where possible.",
    },
    {
        question: "Can I use the music in my own game or project?",
        answer: "No, the soundtrack is not available for use in other projects without permission.",
    },
]

const MusicNote = ({ className, size = "w-24 h-24", opacity = "opacity-15" }) => (
    <div className={`absolute transform ${opacity} ${className} transition-all duration-1000`}>
        <BsMusicNoteBeamed
            className={`${size} text-fl-turkis-light animate-[pulse_4s_ease-in-out_infinite] scale-100 hover:scale-105`}
        />
    </div>
)

export function Music(props) {
    return (
        <>
            {/* Beige background extension to cover the jagged edge */}
            <div className="relative">
                <div className="absolute inset-x-0 -top-16 h-16 bg-fl-beige" />
            </div>

            <Section
                bg="bg-fl-turkis-dark"
                color="text-fl-text"
                className="relative pt-0 mt-0"
                id="Soundtrack"
            >
                {/* Gradient transition at the top */}
                <div className="absolute inset-x-0 -top-32 h-32 bg-gradient-to-b from-fl-beige to-fl-turkis-dark pointer-events-none" />

                <div className="relative pt-16">
                    {/* Background accent - more subtle gradient */}
                    <div className="absolute inset-0 bg-gradient-to-b from-fl-turkis-light/5 to-transparent" />

                    {/* Decorative music notes */}
                    <div className="absolute inset-0 opacity-10">
                        {/* Large notes in the back - show all on desktop, only first two on mobile */}
                        <MusicNote
                            className="absolute left-0 top-[5%] rotate-[-15deg] md:top-24"
                            size="w-32 h-32"
                            opacity="opacity-20"
                        />
                        <MusicNote
                            className="absolute right-0 top-[120%] rotate-[25deg] [animation-delay:0.7s] md:top-32"
                            size="w-32 h-32"
                            opacity="opacity-25"
                        />
                        <MusicNote
                            className="hidden md:block absolute left-1/4 bottom-24 rotate-[-35deg] [animation-delay:1.2s]"
                            size="w-32 h-32"
                            opacity="opacity-20"
                        />

                        {/* Medium notes in the middle - show half on mobile */}
                        <MusicNote
                            className="absolute left-12 top-[25%] rotate-[-15deg] [animation-delay:0.3s] md:top-40"
                            opacity="opacity-15"
                        />
                        <MusicNote
                            className="absolute right-24 top-[95%] rotate-[25deg] [animation-delay:0.5s] md:top-48"
                            opacity="opacity-20"
                        />
                        <MusicNote
                            className="hidden md:block absolute left-32 bottom-32 rotate-[-35deg] [animation-delay:1s]"
                            opacity="opacity-10"
                        />
                        <MusicNote
                            className="hidden md:block absolute right-16 bottom-40 rotate-[15deg] [animation-delay:1.5s]"
                            opacity="opacity-15"
                        />
                        <MusicNote
                            className="absolute left-1/3 top-[140%] rotate-[45deg] [animation-delay:0.8s] md:top-56"
                            opacity="opacity-20"
                        />
                        <MusicNote
                            className="hidden md:block absolute right-1/3 bottom-48 rotate-[-25deg] [animation-delay:1.3s]"
                            opacity="opacity-15"
                        />

                        {/* Small notes in the front - show only two on mobile */}
                        <MusicNote
                            className="absolute left-1/4 top-[45%] rotate-[15deg] [animation-delay:0.2s] md:top-36"
                            size="w-16 h-16"
                            opacity="opacity-10"
                        />
                        <MusicNote
                            className="absolute right-1/4 top-[130%] rotate-[-35deg] [animation-delay:0.9s] md:top-52"
                            size="w-16 h-16"
                            opacity="opacity-15"
                        />
                        <MusicNote
                            className="hidden md:block absolute left-1/2 bottom-20 rotate-[25deg] [animation-delay:1.4s]"
                            size="w-16 h-16"
                            opacity="opacity-10"
                        />
                        <MusicNote
                            className="hidden md:block absolute right-1/2 top-64 rotate-[-15deg] [animation-delay:0.6s]"
                            size="w-16 h-16"
                            opacity="opacity-20"
                        />
                    </div>

                    <div className="relative mx-auto">
                        <div className="max-w-6xl mx-auto">
                            <div className="flex items-center justify-center gap-3 mt-8">
                                <FaMusic className="w-8 h-8 text-fl-turkis-light" />
                                <h2 className="text-3xl uppercase tracking-wider text-fl-white text-center">
                                    <Trans i18nKey="musicTitle">Soundtrack</Trans>
                                </h2>
                            </div>
                            <h3 className="text-fl-white/50 block text-center mb-10 ">
                                <Trans i18nKey="musicTracksDesc">
                                    Includes all 59 tracks from the game
                                </Trans>
                            </h3>

                            <div className="space-y-12">
                                {/* Buy Section */}
                                <div>
                                    <h3 className="text-2xl text-fl-white mb-4 text-center font-medium">
                                        <Trans i18nKey="musicBuyTitle">Buy</Trans>
                                    </h3>
                                    <div className="flex justify-center gap-6 flex-wrap">
                                        {buyLinks
                                            .filter(link => link.url !== "#")
                                            .map(link => {
                                                const Icon = link.icon
                                                return (
                                                    <a
                                                        key={link.name}
                                                        href={link.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="inline-flex items-center px-6 py-4 bg-fl-black/50 hover:bg-fl-turkis-light/20 transition-all duration-300 rounded-xl group shadow-lg shadow-black/20 hover:shadow-fl-turkis-light/20 border border-fl-turkis-light/20 hover:border-fl-turkis-light/40"
                                                    >
                                                        <Icon className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform text-fl-turkis-light group-hover:text-fl-white" />
                                                        <span className="text-lg text-fl-white/90 group-hover:text-fl-white">
                                                            {link.name}
                                                        </span>
                                                    </a>
                                                )
                                            })}
                                    </div>
                                </div>

                                {/* Stream Section */}
                                <div>
                                    <h3 className="text-2xl text-fl-white mb-4 text-center font-medium">
                                        <Trans i18nKey="musicStreamTitle">Stream</Trans>
                                    </h3>
                                    <div className="flex justify-center gap-4 flex-wrap">
                                        {streamLinks
                                            .filter(link => link.url !== "#")
                                            .map(link => {
                                                const Icon = link.icon
                                                return (
                                                    <a
                                                        key={link.name}
                                                        href={link.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="inline-flex items-center px-5 py-3 bg-fl-black/50 hover:bg-fl-turkis-light/20 transition-all duration-300 rounded-xl group shadow-md shadow-black/20 hover:shadow-fl-turkis-light/20 border border-fl-turkis-light/10 hover:border-fl-turkis-light/30"
                                                    >
                                                        <Icon className="w-5 h-5 mr-2 group-hover:scale-110 transition-transform text-fl-turkis-light group-hover:text-fl-white" />
                                                        <span className="text-fl-white/90 group-hover:text-fl-white">
                                                            {link.name}
                                                        </span>
                                                    </a>
                                                )
                                            })}
                                    </div>
                                </div>

                                {/* FAQ Section */}
                                <div className="mt-16">
                                    <h3 className="text-fl-turkis-light/80 text-sm mb-4 text-center">
                                        FAQ
                                    </h3>
                                    <div className="flex justify-center px-4">
                                        <div className="space-y-4 text-sm w-full max-w-lg">
                                            {faqItems.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="border-b border-fl-turkis-light/10 pb-4 last:border-0"
                                                >
                                                    <h4 className="text-fl-white mb-2">
                                                        <Trans
                                                            i18nKey={`musicFaq${index + 1}Question`}
                                                        >
                                                            {item.question}
                                                        </Trans>
                                                    </h4>
                                                    <p className="text-fl-white/70">
                                                        <Trans
                                                            i18nKey={`musicFaq${index + 1}Answer`}
                                                        >
                                                            {item.answer}
                                                        </Trans>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}
