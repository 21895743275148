import React, { useState, useEffect, useRef } from "react"
import { Section } from "../components/Section"
import * as keyartStyle from "../styles/keyart.module.css"
import { useI18next } from "gatsby-plugin-react-i18next"
import { FaMusic } from "react-icons/fa"
import { Trans } from "gatsby-plugin-react-i18next"

export function Keyart() {
    const { language, t } = useI18next()
    const [hasKeyartRendered, setHasKeyartRendered] = useState(false)

    return (
        <Section bg="bg-fl-keyart" color="text-fl-white" className="pt-0 pb-6 -mb-6 md:mb-0">
            <div className="absolute inset-x-0 mx-auto max-w-5xl -mt-20">
                <LangSwitcher />
                <SoundtrackButton />
            </div>
            {hasKeyartRendered && <Particles />}
            <div className={keyartStyle.container} id="Keyart">
                <div className="max-w-full overflow-hidden pt-2">
                    <KeyartImage setRendered={setHasKeyartRendered} />
                </div>
            </div>
            <h1 className="flex items-center flex-col relative -top-24 -mb-10">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[600px] h-[300px] bg-[radial-gradient(ellipse_at_center,_#35C9B5_0%,_#35C9B5_30%,_transparent_70%)] opacity-[0.09]"></div>
                <div className="relative">
                    <img
                        src={`/assets/title_subtitle${language === "de" ? "_de" : ""}.png`}
                        alt={t("headTitle", "Frameland – A short RPG with a big heart.")}
                        width={410}
                        height={110}
                        className="animate-glitch"
                    />
                </div>
            </h1>
        </Section>
    )
}

function SoundtrackButton() {
    return (
        <a
            href="#Soundtrack"
            className="flex flex-col items-center justify-center absolute bg-fl-keyart/80 rounded-full p-5 z-10 left-4 top-[75px] sm:left-6 md:left-6 md:top-[22px] lg:left-8 transition-all duration-300 group border-2 border-fl-turkis-light shadow-xl shadow-fl-turkis-light/30"
            title="Listen to Soundtrack"
        >
            <div className="relative">
                <FaMusic className="w-6 h-6 text-fl-white transition-colors mb-1" />
                <div className="absolute -right-1.5 -top-1.5 w-3 h-3 bg-fl-turkis-light rounded-full animate-pulse" />
            </div>
            <span className="text-fl-white text-[11px] font-medium tracking-wider uppercase">
                <Trans i18nKey="musicBadgeNew">New</Trans>
            </span>
            <span className="text-fl-turkis-light text-[10px] tracking-wider uppercase">
                Soundtrack
            </span>
        </a>
    )
}

function KeyartImage({ setRendered }) {
    return (
        <picture className={keyartStyle.container}>
            <source srcSet="/assets/keyart_full.png" media="(min-width: 768px)" />
            <img
                src="/assets/keyart_mobile.png"
                alt="Keyart with heroes and villains"
                width={842}
                height={843}
                className={keyartStyle.keyartImage}
                onLoad={() => setRendered(true)}
            />
        </picture>
    )
}

function LangSwitcher() {
    const { languages, changeLanguage, language } = useI18next()
    return (
        <div className="flex items-center absolute bg-fl-turkis-dark hover:bg-fl-turkis rounded z-10 right-4 top-24 md:top-12 md:right-12 transition-all duration-300 group border-2 border-fl-turkis-light/30 hover:border-fl-turkis-light shadow-xl hover:shadow-fl-turkis-light/30">
            <img
                src="/assets/icons/icon_lang.png"
                className="block ml-3 opacity-80 -mr-3 w-5 h-5 text-fl-turkis-light group-hover:text-fl-white transition-colors"
                alt=""
            />
            <ul className="flex items-center">
                {languages
                    .filter(lng => language !== lng)
                    .map(lng => (
                        <li key={lng} className="px-2 py-3">
                            <a
                                className="relative top-[-3px] px-2 text-fl-turkis-light group-hover:text-fl-white uppercase tracking-wider text-sm transition-colors"
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    changeLanguage(lng)
                                }}
                            >
                                {lng}
                            </a>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

function Particles() {
    const canvasRef = useRef(null)
    const requestRef = React.useRef()

    const animate = time => {
        if (canvasRef.current) {
            var dt = getDeltaTime()
            update(dt)
            render(canvasRef.current)
        }
        requestRef.current = requestAnimationFrame(animate)
    }

    useEffect(() => {
        init(canvasRef.current)
        requestRef.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(requestRef.current)
    }, [])

    useEffect(() => {
        window.addEventListener("resize", on_resize)
        return () => {
            window.removeEventListener("resize", on_resize)
        }
    }, [])

    return (
        <div className="relative">
            <canvas
                ref={canvasRef}
                width={500}
                height={500}
                className="absolute top-0 z-50 pointer-events-none"
            />
        </div>
    )
}

var particles = new Array()
var particleNr = 85
var canvasHeight = 400
var lastTime = 0
var image
var startGameTime = 0.0
var width = 0
var theCanvas = null

function init(canvas) {
    particles = new Array()
    particleNr = 85
    canvasHeight = 400
    lastTime = 0
    image = null
    startGameTime = 0.0
    width = 0
    theCanvas = null

    canvas.width = window.innerWidth
    canvas.height = getCanvasHeight()
    width = window.innerWidth
    theCanvas = canvas

    startGameTime = new Date().getTime()

    for (var i = 0; i < particleNr; i++) {
        particles.push(new createExplosion())
    }

    image = new Image()
    image.src = "/assets/particle.png"
}

function getCanvasHeight() {
    canvasHeight = document.getElementById("Keyart").getBoundingClientRect().height
    canvasHeight -= 20
    return canvasHeight
}

function on_resize() {
    theCanvas.width = document.documentElement.clientWidth
    theCanvas.height = getCanvasHeight()
    width = window.innerWidth
}

function smoothWind(dt) {
    return (Math.random() - 0.5) * 100.0 * dt
}

function update(dt) {
    const wind = smoothWind(dt)
    for (var i = 0; i < particles.length; i++) {
        var snow = particles[i]
        snow.x += snow.vx * dt
        snow.y += snow.vy * dt
        snow.vx -= wind

        if (!snow.turn) {
            snow.life += dt
            if (snow.life >= snow.totalLife) snow.turn = true
        } else {
            snow.life -= dt
        }

        if (snow.life <= 0.0) {
            resetExplosion(snow)
        }
    }
}

function render(canvas) {
    var context = canvas.getContext("2d")
    context.clearRect(0, 0, canvas.width, canvas.height)

    var imgWidth = image.width

    if (!image) return

    context.save()
    context.globalCompositeOperation = "lighter"

    for (var i = 0; i < particles.length; i++) {
        var progress = particles[i].life / particles[i].totalLife
        const size = imgWidth * (1 - progress * 0.5)
        context.globalAlpha = progress
        context.drawImage(image, particles[i].x - size / 2, particles[i].y - size / 2, size, size)
    }
    context.restore()
}

// --------------------------------------------------------------------------
// Particles
// --------------------------------------------------------------------------
function createExplosion() {
    resetExplosion(this)
}

function resetExplosion(exp) {
    var w = window.innerWidth
    var h = document.documentElement.clientHeight
    exp.x = w / 2 + (Math.random() - 0.5) * w * 0.75
    exp.y = canvasHeight / 2 + (Math.random() - 0.5) * canvasHeight * 0.75
    if (Math.abs(exp.x - w / 2) < 50) {
        exp.x += (Math.random() - 0.5) * 100
    }
    if (Math.abs(exp.y - h / 2) < 50) {
        exp.h += (Math.random() - 0.5) * 50
    }
    exp.life = 0.0
    exp.totalLife = 2.0 + (Math.random() - 0.5) * 2.0
    exp.turn = false
    var speed = 10 + Math.random() * 15
    exp.vx = Math.cos(Math.random() * Math.PI * 2) * speed
    exp.vy = Math.sin(Math.random() * Math.PI * 2) * speed * 0.35
}

// ------------------------------------------------------------------
// Helpers
// ------------------------------------------------------------------
function getMillisecs() {
    return new Date().getTime() - startGameTime
}

function getDeltaTime() {
    var now = getMillisecs()
    var dt = (now - lastTime) / 1000.0
    dt = Math.max(dt, 1.0 / 240.0)
    dt = Math.min(dt, 1.0 / 20.0)
    lastTime = now
    return dt
}
